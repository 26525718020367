
/**
 * @description Transform first character of 1 string to Uppercase
 * @param {String} value
 */
const firstLetterUppercase = function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export default firstLetterUppercase
