import {seats} from '@/services/seats'
import {commonErrors} from '@/services/errors/errors-common'
import * as Types from './mutation-types'
import * as CommonTypes from '../common/mutation-types'

const seatActions = {

  /**
   * @description Get seats
   * @param {Object} commit
   *
   */
  async getSeats ({commit}) {
    let response

    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)

    try {
      // esto tengo que cambiarlo cuando hagan funcionar el endpoint
      response = await seats.getSeats()
      commit(Types.GET_SEATS, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      throw new Error(errorHandled)
    }
  },
  /**
   * @description authorize_seats
   * @param {Object} commit
   * @param {Object} payload contain the selected seats to be authorized
   */
  async authorize_seats ({commit}, payload) {
    let response
    const idSeats = payload.map(seat => seat.id)

    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)

    try {
      response = await seats.authorize_seats(idSeats)
      const authorizatedSeats = response.seats
      commit(Types.AUTHORIZE_SEATS, authorizatedSeats)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)

      commit(Types.AUTHORIZED_SEATS_OK_CLEAR_ALL)
      commit(Types.AUTHORIZED_SEATS_ERROR_CLEAR_ALL)

      if (response.data.seats.length !== 0) {
        commit(Types.AUTHORIZED_SEATS_OK, response.data.seats)
      }

      if (response.data.errordata.length !== 0) {
        commit(Types.AUTHORIZED_SEATS_ERROR, response.data.errordata)
      }

      return response.data
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      throw new Error(errorHandled)
    }
  }

}

export default seatActions
