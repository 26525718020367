import { user } from '../services/user'

export default {
  isAdminUser: (userId) => {
    const hardcodedIds = [
      1,
      2,
      1259,
      1430,
      3063,
      1723,
      1734, // Juan
      1743, // Diego
      3007,
      1442,
      1444,
      1442,
      2747, // Dada
      1316,
      2276,
      1728,
      1971, // Ioni
      1737,
      1969, // Belu
      5044,
      5228,
      4429,
      1724,
      3995,
      3089, // Renzo
      6790, // Iran
      6628, // Lorena
      1260, // Manu
      9022, // Gabriela Gonzalez
      4215, // Florencia Marchese
      7810, // Alejo Troncoso
      9114, // Maria Morono
      8124, // Irina Belluci
      9648, // Juan Carlos Perez (Producción)
      6632, // Juan Carlos Perez (Staging)
      7992, // Rodrigo DLP
      4699, // Oscar Barquet
      7994, // Geovani Perez
      6766, // Agustin Juantorena
      10244, // Miguel Veliz
      10515, // Daniela Tambussi
      10751, // Martin Palermo
      9786, // Denise Adissi
      6750, // Matias Latorraca
      11543, // Natalia Hang
      10747, // Charlie Martinez
      11527, // Alejo Mosin
      11985, // Alejandra Lemosherat
      11869, // Raul Rosario
      10825, // Gabriel Carpineli
      10937, // Leonardo Martins
      5144, // Thiago Gusmao
      13293, // Franco Ponce
      14694, // Alan Barros
      13843 // Ferney
    ]

    return hardcodedIds.includes(userId)
  },
  checkUserIntegrity: (userCached) => {
    const mockIntegrity = {
      id: null,
      username: null,
      organization: null
    }

    const keysIntegrity = Object.keys(mockIntegrity)
    const cachedObjectKeys = Object.keys(userCached)

    return cachedObjectKeys.every((key) => keysIntegrity.indexOf(key) !== -1)
  },
  hasPermission: (permissionKey) => {
    let auth0UserPermissions = user.getCurrentUserPermissions()
    let simpleUserPermissions = auth0UserPermissions.map((p) => p.permission_name)
    return simpleUserPermissions.find((p) => p === permissionKey) || undefined
  }
}
