'user strict'

import getApi from './api'

export const user = {
  /**
   * @description
   * @param {Integer} page
   * @param {Integer} size
   */
  async getPaginatedUsers({ page, size, q = null }) {
    const api = await getApi()

    let userPaginatedURL
    if (q) {
      userPaginatedURL = `user?page=${page}&size=${size}&q=${q}`
    } else {
      userPaginatedURL = `user?page=${page}&size=${size}`
    }
    return api.get(userPaginatedURL)
  },
  /**
   * @description
   * @param {Integer} userId
   */
  async getUserById(userId) {
    const api = await getApi()

    return api.get(`user/${userId}/?org=all`)
  },
  /**
   * @description Get all roles
   */
  async getAllRoles() {
    const api = await getApi()

    return api.get(`user/roles`)
  },
  /**
   * @description Get all permissions
   */
  async getAllPermissions() {
    const api = await getApi()

    return api.get(`user/permissions`)
  },
  /**
   * @description Get user permissions by user id
   * @param {*} idUser
   */
  async getPermissionsByUserId(idUser) {
    const api = await getApi()

    return api.get(`user/permissions/${idUser}`)
  },
  /**
   * @description Allows to create an user given an organization id
   * @param {Object} user contains the data of user to be created
   */
  async createUserForOrganization({ user }) {
    const api = await getApi()

    return api.post(`user/`, user)
  },
  /**
   * @description
   * @param {Integer} idOrganization
   * @param {Object} user
   */
  async updateUserForOrganization({ auth0UserId, serverUserId, user }) {
    const api = await getApi()

    return api.put(`user/${auth0UserId}?serverUserId=${serverUserId}`, user)
  },
  /**
   * @description
   * @param {Integer} userId
   */
  async removeUserById(userId) {
    const api = await getApi()

    return api.delete(`user/${userId}`)
  },
  /**
   * @description
   * @param {Integer} userId
   */
  async addUserById(userId) {
    const api = await getApi()

    return api.post(`user/enable/${userId}`)
  },
  /**
   * @description
   */
  getCurrentUserPermissions() {
    let permissions = window.localStorage.getItem('loggedUserPermissions')
    if (!permissions || permissions === '') {
      return {}
    } else {
      return JSON.parse(permissions)
    }
  }
}
