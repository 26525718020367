import { pricing } from '../../services/pricing'
import { commonErrors } from '../../services/errors/errors-common'
import * as CommonTypes from '../common/mutation-types'
import * as Types from './mutation-types'

const pricingActions = {
  async getPricingList({ commit }, payload = {}) {
    let list
    commit(Types.PRICING_LOADING, true)
    commit(Types.CLEAR_PRICING_ERRORS)
    try {
      list = await pricing.getPricingList(payload)
      commit(Types.GET_PRICINGS, list.data.response)
      commit(Types.PRICING_LOADING, false)
      commit(CommonTypes.REQUEST_SUCCESS)
      return list.data.response
    } catch (error) {
      let errorHandled = error
      commit(Types.ADD_PRICING_ERROR)
      commit(Types.PRICING_LOADING, false)
      throw new Error(errorHandled)
    }
  },
  async getClusters({ commit }) {
    let response
    commit(Types.CLUSTERS_LOADING, true)
    commit(Types.CLEAR_CLUSTERS_ERRORS)
    try {
      response = await pricing.getClusters()
      commit(Types.GET_CLUSTERS, response.data)
      commit(Types.CLUSTERS_LOADING, false)
      return response.data
    } catch (error) {
      let errorHandled = error
      commit(Types.CLUSTERS_LOADING, false)
      commit(Types.ADD_CLUSTERS_ERROR)
      throw new Error(errorHandled)
    }
  },
  async getPlatforms({ commit }) {
    let response
    commit(Types.PLATFORMS_LOADING, true)
    commit(Types.CLEAR_PLATFORMS_ERRORS)
    try {
      response = await pricing.getPlatforms()
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(Types.GET_PLATFORMS, response.data)
      commit(Types.PLATFORMS_LOADING, false)
      return response.data
    } catch (error) {
      let errorHandled = error
      commit(Types.ADD_PLATFORMS_ERROR)
      commit(Types.PLATFORMS_LOADING, false)
      throw new Error(errorHandled)
    }
  },
  async getSegmentTypes({ commit }) {
    let response
    commit(Types.SEGMENTS_LOADING, true)
    commit(Types.CLEAR_SEGMENTS_ERRORS)
    try {
      response = await pricing.getSegmentTypes()
      console.log('getSegmentTypes -> response', response.data.response)
      commit(Types.SET_SEGMENT_TYPES, response.data.response)
      commit(Types.SEGMENTS_LOADING, false)
      commit(CommonTypes.REQUEST_SUCCESS)
      return response.data.response
    } catch (error) {
      let errorHandled = error
      commit(Types.ADD_SEGMENTS_ERROR)
      commit(Types.SEGMENTS_LOADING, false)
      throw new Error(errorHandled)
    }
  },
  async getClients({ commit }) {
    let response
    commit(Types.CLIENTS_LOADING, true)
    commit(Types.CLEAR_CLIENTS_ERRORS)
    try {
      response = await pricing.getClients()
      console.log('getClients -> response', response)
      commit(Types.GET_CLIENTS, response.data)
      commit(Types.CLIENTS_LOADING, false)
      commit(CommonTypes.REQUEST_SUCCESS)
      return response.data.response
    } catch (error) {
      let errorHandled = error
      commit(Types.CLIENTS_LOADING, false)
      commit(Types.ADD_CLIENTS_ERROR)
      throw new Error(errorHandled)
    }
  },
  async postRules({ commit }, payload) {
    console.log('postRules -> payload', payload)
    let response
    commit(Types.POSTING_RULES, true)
    try {
      response = await pricing.postRules(payload)
      commit(Types.POSTING_RULES, false)
      return response
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      throw new Error(errorHandled)
    }
  },
  async deleteRuleAction({ commit }, { idRule, clientName }) {
    let list
    commit(Types.PRICING_LOADING, true)
    commit(Types.CLEAR_PRICING_ERRORS)
    try {
      await pricing.deleteRule(idRule)
      list = await pricing.getPricingList()
      commit(Types.GET_PRICINGS, list.data.response)
      commit(Types.PRICING_LOADING, false)
      commit(CommonTypes.REQUEST_SUCCESS)
    } catch (error) {
      commit(CommonTypes.ADD_SERVER_ERROR)
      commit(CommonTypes.REQUEST_FAIL)
      throw new Error(error)
    }
  }
}

export default pricingActions
