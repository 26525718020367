'use strict'

import getApi from './api'

export const organization = {
  /**
   * @description Enable/Disable Product for organization
   * @param {Integer} idOrganization
   * @param {Integer} idProduct
   * @param {Integer} idOrganization
   */
  async setActivationProductForOrganization({ organizationId, productId, operation }) {
    const api = await getApi()

    if (operation === 0) {
      // For product enabling
      return api.post(`organization/${organizationId}/enable/${productId}`)
    } else {
      // For product disabling
      return api.post(`organization/${organizationId}/disable/${productId}`)
    }
  },
  /**
   * @description Return the organization info filtered by id
   * @param {Integer} id Id of the organization to get data
   * @returns {Promise}
   */
  async getOrganizationById(id, getChildren = false, getPricingRules = false, getSegments = false) {
    const api = await getApi()

    if (process.env.NODE_ENV === 'development') {
      console.log('id to search', id)
    }
    let url = `organization/${id}?`

    let queryParams = []

    if (getChildren) {
      queryParams.push('getChildren=true')
    }
    if (getPricingRules) {
      queryParams.push('getPricingRules=true')
    }
    if (getSegments) {
      queryParams.push('getSegments=true')
    }

    try {
      return api.get(url + queryParams.join('&'))
    } catch (error) {
      throw error
    }
  },
  /**
   * @description service for getting all organizations
   * @returns {Promise}
   */
  async getOrganizations() {
    const api = await getApi()

    return api.get('organization/')
  },
  /**
   * @description Returns uploads with pagination parameters
   * @param {Object} payload Configuration object for pagination endpoint
   * @returns {Promise}
   */
  async getPaginatedOrganizations({ page, size, q = null, type = 0 }) {
    const api = await getApi()

    let organizationPaginatedURL

    if (q) {
      if (type) {
        organizationPaginatedURL = `organization?page=${page}&size=${size}&q=${q}&type=${type}`
      } else {
        organizationPaginatedURL = `organization?page=${page}&size=${size}&q=${q}`
      }
    } else {
      if (type) {
        organizationPaginatedURL = `organization?page=${page}&size=${size}&type=${type}`
      } else {
        organizationPaginatedURL = `organization?page=${page}&size=${size}`
      }
    }
    return api.get(organizationPaginatedURL)
  },
  /**
   * @description
   * @param {Object} payload
   * @returns {Promise}
   */
  async createOrganization({ name, base64ImageFile, parentId }) {
    const api = await getApi()

    let organization = { name, base64ImageFile, parentId }

    return api.post('organization/', organization)
  },
  async getAppThemes() {
    const api = await getApi()

    return api.get('/theme/')
  },
  async setAppForOrganization({ appId, organization }) {
    const api = await getApi()
    return api.post(`organization/${organization.id}/configure/${appId}`, organization)
  },
  async fetchCountries() {
    const api = await getApi()
    return api.get('/common/list/countries?feature=planner')
  },
  /**
   * @description
   * @param {Integer} appId
   * @param {Object} organization
   */
  async updateAppForOrganization({ appId, organization }) {
    const api = await getApi()
    return api.put(`organization/${organization.id}/configure/${appId}`, organization)
  },
  /**
   * @description
   * @param {Integer} id
   * @param {Object} payload
   * @returns {Promise}
   */
  async updateOrganization(idOrganization = null, payload) {
    const api = await getApi()

    let organization = { ...payload }

    return api.put(`organization/${idOrganization}/`, organization)
  }
}
