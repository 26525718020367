import seatsActions from './actions'
import seatsMutations from './mutations'
import seatsGetters from './getters'

const seats = {
  state: {
    seats: [],
    authSeats: [],
    authSeatsOk: [],
    authSeatsError: [],
    pagination: {
      current: null,
      perPage: null,
      total: null
    }
  },
  actions: seatsActions,
  mutations: seatsMutations,
  getters: seatsGetters
}

export default seats
