import * as Types from './mutation-types'

const commonMutations = {
  [Types.LOADING_SHOW](state) {
    state.showLoader = true
  },
  [Types.LOADING_HIDE](state) {
    state.showLoader = false
  },
  [Types.REQUEST_SUCCESS](state) {
    state.requestSuccess = true
    state.serverError = false
    state.showLoader = false
  },
  [Types.REQUEST_FAIL](state) {
    state.requestSuccess = false
    state.showLoader = false
  },
  [Types.ADD_ERROR](state, payload) {
    state.respError = payload
    state.errors.push(payload)
  },
  [Types.ADD_SERVER_ERROR](state) {
    state.serverError = true
  },
  [Types.CLEAR_ERRORS](state) {
    state.serverError = false
  },
  [Types.TOKEN_VALID](state) {
    state.tokenValid = true
  },
  [Types.TOKEN_INVALID](state) {
    state.tokenValid = false
  }
}

export default commonMutations
