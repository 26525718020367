'exchange rate strict'

import getApi from './api'

export const exchangeRate = {
  /**
   * @description All exchanges rates or by date
   */
  async getExchangeRates({ q = null }) {
    const api = await getApi()

    let exchangeRatesURL
    if (q) {
      exchangeRatesURL = `exchange-rate?q=${q}`
    } else {
      exchangeRatesURL = `exchange-rate`
    }
    return api.get(exchangeRatesURL)
  },
  /**
   * @description All currencies
   */
  async getExchangeRatesCountries() {
    const api = await getApi()

    let exchangeRatesCountriesURL = `exchange-rate/currency_codes`

    return api.get(exchangeRatesCountriesURL)
  },
  /**
   * @description Allows to create an exchange rate
   * @param {Object} user contains the data of rate to be created
   */
  async createExchangeRate({ rate }) {
    const api = await getApi()
    let createExchangeRates = `exchange-rate`
    return api.post(createExchangeRates, rate)
  },
  /**
   * @description Allows to edit an exchange rate
   * @param {Object} user contains the data of rate to be edited
   */
  async editExchangeRate(rate) {
    const api = await getApi()

    let editExchangeRates = `exchange-rate`
    return api.put(editExchangeRates, rate)
  },
  /**
   * @description
   * @param {Integer} rateId
   */
  async removeRateById(rateId) {
    const api = await getApi()
    return api.delete(`exchange-rate/${rateId}`)
  }
}
