import * as Types from './mutation-types'
import { orderBy } from 'lodash'

const exchangeRateMutations = {
  [Types.BEGIN_FETCHING_EXCHANGE_RATES](state) {
    state.requestExchangeRates = true
  },
  [Types.CLEAR_EXCHANGE_RATES_LIST_DATA](state) {
    state.exchangeRates = []
  },
  [Types.END_FETCHING_EXCHANGE_RATES](state) {
    state.requestExchangeRates = false
  },
  [Types.POPULATE_EXCHANGE_RATES](state, payload) {
    let rates = payload.map(user => {
      return { ...user }
    })
    rates = orderBy(rates, ['created'], ['desc'])
    state.exchangeRates = rates
  },
  [Types.BEGIN_FETCHING_EXCHANGE_RATES_COUNTRIES](state) {
    state.requestExchangeRatesCountries = true
  },
  [Types.CLEAR_EXCHANGE_RATES_COUNTRIES_LIST_DATA](state) {
    state.exchangeRatesCountries = []
  },
  [Types.END_FETCHING_EXCHANGE_RATES_COUNTRIES](state) {
    state.requestExchangeRatesCountries = false
  },
  [Types.POPULATE_EXCHANGE_RATES_COUNTRIES](state, payload) {
    state.exchangeRatesCountries = payload
  },
  [Types.SET_EXCHANGE_RATES](state, payload) {
    state.newExchangeRate = payload
  },
  [Types.BEGIN_EDITING_EXCHANGE_RATE](state) {
    state.requestEditExchangeRate = true
    state.successEditExchangeRate = false
  },
  [Types.END_EDITING_EXCHANGE_RATE](state) {
    state.requestEditExchangeRate = false
  },
  [Types.SUCCESS_EDITING_EXCHANGE_RATE](state, payload) {
    state.successEditExchangeRate = payload
  },
  [Types.BEGIN_REMOVE_EXCHANGE_RATE](state) {
    state.requestRemoveExchangeRate = true
    state.successRemoveExchangeRate = false
  },
  [Types.END_REMOVE_EXCHANGE_RATE](state) {
    state.requestRemoveExchangeRate = false
  },
  [Types.SUCCESS_REMOVE_EXCHANGE_RATE](state, payload) {
    state.successRemoveExchangeRate = payload
  }
}

export default exchangeRateMutations
