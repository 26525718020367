import organizationActions from './actions'
import organizationMutations from './mutations'
import organizationGetters from './getters'

const organization = {
  state: {
    organization: {},
    organizations: [],
    organizationProducts: [],
    organizationApp: {},
    organizationAppThemes: [],
    organizationOperation: {
      create: false,
      update: false
    },
    requestOrganizations: false,
    requestOrganizationError: false,
    requestAppOrganization: false,
    requestAppOrganizationError: false,
    duplicatedOrganization: false,
    pagination: {
      current: null,
      perPage: null,
      total: null
    }
  },
  actions: organizationActions,
  mutations: organizationMutations,
  getters: organizationGetters
}

export default organization
