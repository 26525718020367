const seatsGetters = {
  seats (state) {
    return state.seats
  },
  fb_seats (state) {
    return state.seats.filter(item => item.platformId === 25)
  },
  auth_seats_ok (state) {
    return state.authSeatsOk
  },
  auth_seats_error (state) {
    return state.authSeatsError
  }
}

export default seatsGetters
