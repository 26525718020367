'user strict'

import getApi from './api'

export const selfServe = {
  /**
   * @getSelfServeOrgs get the organizations from the selfserve app
   * @param {status}
   */
  async getSelfServeOrgs(params) {
    const api = await getApi()

    const { status, ...rest } = params

    let translatedStatus = {}
    switch (status) {
      case 'pending':
        translatedStatus = { pending: true, signed: true }
        break
      case 'signed':
        translatedStatus = { signed: false }
        break
      case 'rejected':
        translatedStatus = { rejected: true }
        break
      case 'approved':
        translatedStatus = { approved: true }
    }

    return api.get('/self-serve', { params: { ...rest, ...translatedStatus } })
  },
  /**
   * @approveSelfServeOrg get the organizations from the selfserve app
   * @param { orgId}
   */
  async approveSelfServeOrg(orgId) {
    const api = await getApi()

    return api.post(`/self-serve/${orgId}/approve`)
  },
  /**
   * @rejectSelfServeOrg get the organizations from the selfserve app
   * @param { orgId }
   */
  async rejectSelfServeOrg(orgId) {
    const api = await getApi()

    return api.post(`/self-serve/${orgId}/reject`)
  },
  /**
   * @getSelfServeOrgInfo get the organizations from the selfserve app
   * @param { orgId }
   */
  async getSelfServeOrgsInfo(orgId) {
    const api = await getApi()

    return api.get(`/self-serve/${orgId}/users`)
  }
}
