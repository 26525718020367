import * as Types from './mutation-types'

const authMutations = {
  [Types.DESTROY_SESSION](state) {
    state.loginSuccess = false
  },
  [Types.SET_USER](state, payload) {
    state.user = payload
  },
  [Types.ERROR_LOGIN](state, payload) {
    state.errorLogin = payload
  },
  [Types.LOGIN_SUCCESS](state) {
    state.loginSuccess = true
  }
}

export default authMutations
