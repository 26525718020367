import configActions from './actions'
import configMutations from './mutations'
import configGetters from './getters'

const configuration = {
  state: {
    appThemes: []
  },
  actions: configActions,
  mutations: configMutations,
  getters: configGetters
}

export default configuration
