import userActions from './actions'
import userMutations from './mutations'
import userGetters from './getters'

const user = {
  state: {
    userOrganization: {},
    users: [],
    userOperation: {
      create: false,
      update: false
    },
    selectedUserPermissions: [],
    requestUsers: false,
    requestUser: false,
    requestUserError: false,
    duplicatedUser: false,
    shortQuerySearchError: false,
    pagination: {
      current: null,
      perPage: null,
      total: null
    },

    fetchingAllPermissions: false,
    fetchingPermissionsByUserId: false,
    fetchingAllRoles: false,
    allPermissions: [],
    permissionsByUserId: [],
    allRoles: []
  },
  actions: userActions,
  mutations: userMutations,
  getters: userGetters
}

export default user
