import * as ErrorTypes from './common-error-types'

export const commonErrors = {
  _getErrorStatus(error) {
    if (error.request) {
      return error.request.status
    }
    return 'error'
  },
  _getErrorMessage(error) {
    return error.message
  },
  isTokenInvalid(error) {
    let status = this._getErrorStatus(error)
    return status === 401
  },
  isRequestError(error) {
    return error.request
  },
  isNetworkError(error) {
    let errorHandled = error
    return this._getErrorMessage(error) && errorHandled.message.toUpperCase() === ErrorTypes.networkError
  },
  isBadRequest(error) {
    return this._getErrorStatus(error) === 400
  },
  isTimeoutError(error) {
    let errorHandled = error
    return this._getErrorMessage(error) && errorHandled.message.toUpperCase()
  },
  isInternalError(error) {
    let errorHandled = error
    return this._getErrorStatus(errorHandled) === 500
  }
}
