
export const fileHelper = {
  convertToBase64 (file) {
    let reader = new FileReader()
    return new Promise(resolve => {
      reader.onloadend = () => {
        let fileProcessed = {
          name: file.name,
          data: reader.result
        }
        resolve(fileProcessed)
      }
      reader.readAsDataURL(file)
    })
  }
}
