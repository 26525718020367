import * as Types from './mutation-types'
import { clone, orderBy } from 'lodash'

const organizationMutations = {
  [Types.ADD_DUPLICATED_ERROR](state) {
    state.duplicatedName = true
  },
  [Types.ADD_ORGANIZATION_SERVER_ERROR](state) {
    state.requestOrganizationError = true
  },
  [Types.BEGIN_FETCHING_THEMES](state, payload) {
    state.requestAppThemes = true
  },
  [Types.SET_APP_THEMES](state, payload) {
    state.organizationAppThemes = payload
  },
  [Types.CLEAR_ORGANIZATION_ERRORS](state) {
    state.requestOrganizationError = false
    state.duplicatedName = false
  },
  [Types.CLEAR_ORGANIZATIONS_DATA](state) {
    state.organizations = []
  },
  [Types.CREATE_ORGANIZATION](state) {
    state.organizationOperation.create = true
    state.organizationOperation.update = false
  },
  [Types.POPULATE_ORGANIZATIONS](state, payload) {
    let organizations = clone(payload)
    organizations = orderBy(organizations, ['created'], ['desc'])
    state.organizations = organizations
  },
  [Types.SET_ORGANIZATION](state, payload) {
    state.organization = payload
  },
  [Types.SET_ORGANIZATION_PRODUCTS](state, payload) {
    state.organizationProducts = payload
  },
  [Types.SET_PRODUCT_ACTIVATION](state, { productId, enabled, operation }) {
    state.organizationProducts.find(product => {
      return product.id === productId
    }).enabled = !enabled
  },
  [Types.SET_PAGINATION_ORGANIZATION](state, payload) {
    let { current, perPage, total } = payload
    state.pagination = { current, perPage, total }
  },
  [Types.UPDATE_ORGANIZATION](state) {
    state.organizationOperation.create = false
    state.organizationOperation.update = true
  },
  [Types.SELECTED_ORGANIZATION](state, payload) {
    state.organization = payload
  },
  [Types.BEGIN_FETCHING_ORGANIZATIONS](state) {
    state.requestOrganizations = true
  },
  [Types.END_FETCHING_ORGANIZATIONS](state) {
    state.requestOrganizations = false
  },
  [Types.END_FETCHING_THEMES](state) {
    state.requestAppThemes = false
  }
}

export default organizationMutations
