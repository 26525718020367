import * as Types from './mutation-types'

const pricingMutations = {
  [Types.GET_PRICINGS](state, payload) {
    state.prices = payload
  },
  [Types.GET_CLUSTERS](state, payload) {
    state.clusters = payload
  },
  [Types.GET_PLATFORMS](state, payload) {
    state.platforms = payload
  },
  [Types.SET_SEGMENT_TYPES](state, payload) {
    state.segmentType = payload
  },
  [Types.GET_CLIENTS](state, payload) {
    state.clients = payload
  },
  [Types.PRICING_LOADING](state, payload) {
    state.requestPrices = payload
  },
  [Types.PLATFORMS_LOADING](state, payload) {
    state.requestPlatforms = payload
  },
  [Types.CLUSTERS_LOADING](state, payload) {
    state.requestClusters = payload
  },
  [Types.SEGMENTS_LOADING](state, payload) {
    state.requestSegmentTypes = payload
  },
  [Types.CLIENTS_LOADING](state, payload) {
    state.requestClients = payload
  },
  [Types.POSTING_RULES](state, payload) {
    state.postingRules = payload
  },
  [Types.ADD_PRICING_ERROR](state) {
    state.requestPricesError = true
  },
  [Types.ADD_CLIENTS_ERROR](state) {
    state.requestClientsError = true
  },
  [Types.ADD_CLUSTERS_ERROR](state) {
    state.requestClustersError = true
  },
  [Types.ADD_PLATFORMS_ERROR](state) {
    state.requestPlatformsError = true
  },
  [Types.ADD_SEGMENTS_ERROR](state) {
    state.requestSegmentsError = true
  },
  [Types.CLEAR_PRICING_ERRORS](state) {
    state.requestPricesError = false
  },
  [Types.CLEAR_CLIENTS_ERRORS](state) {
    state.requestClientsError = false
  },
  [Types.CLEAR_PLATFORMS_ERRORS](state) {
    state.requestPlatformsError = false
  },
  [Types.CLEAR_SEGMENTS_ERRORS](state) {
    state.requestSegmentsError = false
  },
  [Types.CLEAR_CLUSTERS_ERRORS](state) {
    state.requestClustersError = false
  }
}
export default pricingMutations
