const userGetters = {
  organizationUser(state) {
    return state.userOrganization
  },
  users(state) {
    return state.users
  },
  usersPagination(state) {
    return state.pagination
  },

  allPermissions(state) {
    return state.allPermissions
  },
  allRoles(state) {
    return state.allRoles
  },
  permissionsByUserId(state) {
    return state.permissionsByUserId
  },

  selectedUserPermissions(state) {
    return state.selectedUserPermissions
  },
  totalUsers(state) {
    return state.pagination.total !== state.pagination.perPage ? state.pagination.total * state.pagination.perPage : state.pagination.total
  },
  requestUsers(state) {
    return state.requestUsers
  },
  requestUser(state) {
    return state.requestUser
  },

  fetchingAllPermissions(state) {
    return state.fetchingAllPermissions
  },
  fetchingAllRoles(state) {
    return state.fetchingAllRoles
  },
  fetchingPermissionsByUserId(state) {
    return state.fetchingPermissionsByUserId
  },

  requestUserError(state) {
    return state.requestUserError
  },
  duplicatedUser(state) {
    return state.duplicatedUser
  },
  shortQuerySearchError(state) {
    return state.shortQuerySearchError
  },

  allPermissions(state) {
    return state.allPermissions
  },
  permissionsByUserId(state) {
    return state.permissionsByUserId
  }
}

export default userGetters
