import en from './en'
import es from './es'
import pt from './pt'

const messages = {
  en,
  es,
  pt
}

export default messages
