const selfServeGetters = {
  selfServeOrganizations(state) {
    return state.organizations
  },
  selfServeTotalItems(state) {
    return state.pagination.total
  },
  selfServeOrganizationsError(state) {
    return state.requestOrganizationsError
  },
  selfServeOrganizationsRequest(state) {
    return state.requestOrganizations
  },
  selfServeApproveOrganizationError(state) {
    return state.requestApproveOrganizationError
  },
  selfServeApproveOrganizationSuccess(state) {
    return state.requestApproveOrganizationSuccess
  },
  selfServeRejectOrganizationError(state) {
    return state.requestRejectOrganizationError
  },
  selfServeRejectOrganizationSuccess(state) {
    return state.requestRejectOrganizationSuccess
  },
  selfServeGetOrganizationDataSuccess(state) {
    return state.requestGetOrganizationInfoSuccess
  },
  selfServeGetOrganizationDataError(state) {
    return state.requestGetOrganizationInfoError
  },
  selfServeRequestGetOrganizationData(state) {
    return state.requestGetOrganizationInfo
  }
}

export default selfServeGetters
