import pricingActions from './actions'
import pricingGetters from './getters'
import pricingMutations from './mutations'

const pricing = {
  state: {
    prices: [],
    clients: [],
    clusters: [],
    platforms: [],
    segmentType: [],
    requestPrices: false,
    requestClients: false,
    requestClusters: false,
    requestSegmentTypes: false,
    requestPlatforms: false,
    requestPricesError: false,
    requestClientsError: false,
    requestClustersError: false,
    requestSegmentsError: false,
    requestPlatformsError: false,
    postingRules: false,
    pagination: {
      current: null,
      perPage: null,
      total: null
    }
  },
  actions: pricingActions,
  getters: pricingGetters,
  mutations: pricingMutations
}

export default pricing
