import Vue from 'vue'
import Router from 'vue-router'
import utils from './utils/user-utils'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/login/callback',
      name: 'loginCallback',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/dashboard',
      // route level code-splitting
      // this generates a separate chunk (dashboard.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ './components/dashboard/DashboardStart.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: '/organizations',
          name: 'organizationStart',
          component: () => import(/* webpackChunkName: "organizations" */ './views/Organization.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: '/organizations/list',
              name: 'organization',
              component: () => import(/* webpackChunkName: "organizations" */ './components/organization/OrganizationTable.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/organizations/new',
              name: 'organizationCreate',
              component: () => import(/* webpackChunkName: "organizations" */ './components/organization/OrganizationCreate.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/organizations/:id',
              name: 'organizationEdit',
              props: true,
              component: () => import(/* webpackChunkName: "organizations" */ './components/organization/OrganizationCreate.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/organizations/confirm/:id',
              name: 'organizationConfirmation',
              props: true,
              component: () => import(/* webpackChunkName: "organizations" */ './components/organization/OrganizationConfirmation.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/organizations/apps/:id',
              name: 'organizationApps',
              props: true,
              component: () => import(/* webpackChunkName: "organizations" */ './components/organization/OrganizationApps.vue'),
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: '/users',
          name: 'userStart',
          component: () => import(/* webpackChunkName: "users" */ './views/User.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: '/users/list',
              name: 'user',
              props: true,
              component: () => import(/* webpackChunkName: "users" */ './components/user/UserTable.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/users/new',
              name: 'userCreate',
              component: () => import(/* webpackChunkName: "users" */ './components/user/UserCreate.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/users/:id',
              name: 'userEdit',
              props: true,
              component: () => import(/* webpackChunkName: "users" */ './components/user/UserCreate.vue'),
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: '/seats',
          name: 'listSeats',
          component: () => import(/* webpackChunkName: "seats" */ './views/Seats.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: '/seats/list',
              name: 'seats',
              props: true,
              component: () => import(/* webpackChunkName: "seats" */ './components/seats/SeatTable.vue'),
              meta: { requiresAuth: true }
            },
            {
              path: '/seats/confirm/',
              name: 'seatConfirmation',
              props: true,
              component: () => import(/* webpackChunkName: "organizations" */ './components/seats/SeatConfirmation.vue'),
              meta: { requiresAuth: true }
            }
          ]
        },
        {
          path: '/exchange-rates',
          name: 'exchangeRate',
          component: () => import(/* webpackChunkName: "ExchangeRates" */ './views/ExchangeRates.vue'),
          meta: { requiresAuth: true, restrictedByPermission: 'exchange:view' },
          children: [
            {
              path: '/exchange-rates/list',
              name: 'exchangeRate',
              component: () => import('./components/exchangeRate/ExchangeRateTable.vue'),
              props: (route) => ({ query: route.query.date }),
              meta: { requiresAuth: true, restrictedByPermission: 'exchange:list' }
            },
            {
              path: '/exchange-rates/new',
              name: 'createExchangeRate',
              component: () => import('./components/exchangeRate/ExchangeRateCreate.vue'),
              props: (route) => ({ query: route.query.date }),
              meta: { requiresAuth: true, restrictedByPermission: 'exchange:update' }
            }
          ]
        },
        {
          path: '/pricing/list',
          name: 'pricing',
          props: true,
          component: () => import('./components/pricing/PricingTable.vue'),
          meta: { requiresAuth: true, onlyAdmins: true }
        },
        {
          path: '/pricing/rules/:id',
          name: 'pricingRules',
          props: true,
          component: () => import(/* webpackChunkName: "organizations" */ './components/pricing/PricingEdit.vue'),
          meta: { requiresAuth: true, onlyAdmins: true }
        },
        {
          path: '/pricing/create',
          name: 'ruleCreate',
          props: true,
          component: () => import(/* webpackChunkName: "organizations" */ './components/pricing/PricingCreate.vue'),
          meta: { requiresAuth: true, onlyAdmins: true }
        },
        {
          path: '/pricing/created/:id',
          name: 'ruleCreated',
          props: true,
          component: () => import(/* webpackChunkName: "organizations" */ './components/pricing/PricingCreate.vue'),
          meta: { requiresAuth: true, onlyAdmins: true }
        },
        {
          path: '/selfserve/list',
          name: 'self-serve',
          props: true,
          component: () => import('./components/selfserve/SelfServeTable.vue'),
          meta: { requiresAuth: true, onlyAdmins: true }
        }
      ]
    },
    {
      path: '*',
      redirect: '/login'
    }
  ],
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const userId = parseInt(window.localStorage.getItem('user_id'))
  const matchedForAdmins = to.matched.some((record) => record.meta.onlyAdmins)
  const matchedForPermissions = to.matched.filter((record) => record.meta.restrictedByPermission)

  for (let index = 0; index < matchedForPermissions.length; index++) {
    const element = matchedForPermissions[index]
    let record = utils.hasPermission(element.meta.restrictedByPermission)
    if (!record) {
      next('/dashboard')
      return
    }
  }

  if (userId && matchedForAdmins && !utils.isAdminUser(userId)) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
