import exchangeRateActions from './actions'
import exchangeRateMutations from './mutations'
import exchangeRateGetters from './getters'

const exchangeRate = {
  state: {
    exchangeRates: [],
    requestExchangeRates: false,
    requestExchangeRatesError: false,
    exchangeRatesCountries: [],
    requestExchangeRatesCountries: false,
    requestExchangeRatesCountriesError: false,
    requestEditExchangeRate: false,
    successEditExchangeRate: false,
    requestRemoveExchangeRate: false,
    successRemoveExchangeRate: false
  },
  actions: exchangeRateActions,
  mutations: exchangeRateMutations,
  getters: exchangeRateGetters
}

export default exchangeRate
