import { auth } from '@/services/auth'
import * as Types from './mutation-types'
import * as OrganizationTypes from '../organization/mutation-types'
import * as CommonTypes from '../common/mutation-types'
import utils from '../../utils/user-utils'

const authActions = {
  /**
   * @description Action for getting user Data
   * @param {Object} commit context from the store
   */
  async getLoggedUser({ commit }) {
    if (window.localStorage.user) {
      const user = await auth.getAuthenticatedUser()
      const hasIntegrity = utils.checkUserIntegrity(user)
      commit(CommonTypes.TOKEN_VALID)
      commit(Types.SET_USER, user)
      return hasIntegrity
    }
    return false
  },
  /**
   * @description Action for the redirection to auth0
   *
   */
  signIn() {
    auth.loginToAuth0()
  },
  /**
   * @description Action for retriving the tokens for the user
   * @param code Code obtained by singin in auth0
   * @param {Object} commit context from the store
   */
  async getAuthUser({ commit }, code) {
    let response
    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      response = await auth.getUser(code)
      await auth.setUser(response.data)
      commit(Types.LOGIN_SUCCESS)
    } catch (error) {
      commit(Types.ERROR_LOGIN, error.message)
      throw new Error(error)
    } finally {
      commit(CommonTypes.LOADING_HIDE)
    }
  },
  /**
   * @description Action for logging out and destroy session on application
   * @param {Object} commit context from the store
   */
  async signOut({ commit }) {
    await auth.signOut()
    commit(Types.DESTROY_SESSION)
    commit(OrganizationTypes.CLEAR_ORGANIZATIONS_DATA)
  }
}

export default authActions
