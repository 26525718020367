export const GET_PRICINGS = 'GET_PRICINGS'
export const GET_CLIENTS = 'GET_CLIENTS'
export const GET_CLUSTERS = 'GET_CLUSTERS'
export const GET_PLATFORMS = 'GET_PLATFORMS'
export const SET_SEGMENT_TYPES = 'SET_SEGMENT_TYPES'
export const PRICING_LOADING = 'PRICING_LOADING'
export const PLATFORMS_LOADING = 'PLATFORMS_LOADING'
export const CLUSTERS_LOADING = 'CLUSTERS_LOADING'
export const SEGMENTS_LOADING = 'SEGMENTS_LOADING'
export const CLIENTS_LOADING = 'CLIENTS_LOADING'
export const POSTING_RULES = 'POSTING_RULES'
export const ADD_PRICING_ERROR = 'ADD_PRICING_ERROR'
export const ADD_CLIENTS_ERROR = 'ADD_CLIENTS_ERROR'
export const ADD_CLUSTERS_ERROR = 'ADD_CLUSTERS_ERROR'
export const ADD_PLATFORMS_ERROR = 'ADD_PLATFORMS_ERROR'
export const ADD_SEGMENTS_ERROR = 'ADD_SEGMENTS_ERROR'
export const CLEAR_PRICING_ERRORS = 'CLEAR_PRICING_ERRORS'
export const CLEAR_CLIENTS_ERRORS = 'CLEAR_CLIENTS_ERRORS'
export const CLEAR_PLATFORMS_ERRORS = 'CLEAR_PLATFORMS_ERRORS'
export const CLEAR_SEGMENTS_ERRORS = 'CLEAR_SEGMENTS_ERRORS'
export const CLEAR_CLUSTERS_ERRORS = 'CLEAR_CLUSTERS_ERRORS'
