import axios from 'axios'

import SERVER from '../server'

export default async () => {
  let api = axios.create({
    baseURL: `${SERVER.api}`,
    timeout: process.env.VUE_APP_DEFAULT_TIMEOUT
  })

  api.interceptors.request.use(
    function (request) {
      let token = window.localStorage.getItem('token')
      let rt = window.localStorage.getItem('rt')
      request.headers.common['Authorization'] = `Bearer ${token}`
      request.headers.common['Rt'] = rt
      return request
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    function (response) {
      if (response.headers.at) {
        window.localStorage.setItem('token', response.headers.at)
      }
      return response
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  return api
}
