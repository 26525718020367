import { selfServe } from '@/services/selfserve'
import { commonErrors } from '@/services/errors/errors-common'
import * as Types from './mutation-types'
import * as CommonTypes from '../common/mutation-types'

const selfServeActions = {
  async getSelfServeOrgs({ commit }, payload) {
    let response
    commit(Types.BEGIN_FETCHING_SELF_SERVE_ORGANIZATIONS)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_SELF_SERVE_ORGANIZATION_ERRORS)
    try {
      const page = payload && payload.page ? payload.page : 1
      const q = payload && payload.q ? payload.q : null
      const status = payload && payload.status ? payload.status : 'pending'

      const order = payload && payload.sortOrder ? (payload.sortOrder === 'desc' ? 0 : 'asc') : null

      let orderField = (payload && payload.sortField) || null
      switch (orderField) {
        case 'createdAt':
          orderField = 'date'
          break
        case 'productId':
          orderField = 'product'
          break
      }
      response = await selfServe.getSelfServeOrgs({ page, q, status, order_by: orderField, order })
      commit(Types.POPULATE_SELF_SERVE_ORGANIZATIONS, response.data.rows)
      commit(Types.SET_SELF_SERVE_PAGINATION, { current: page, total: response.data.count })
      commit(Types.END_FETCHING_SELF_SERVE_ORGANIZATIONS)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_SELF_SERVE_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_SELF_SERVE_ORGANIZATIONS)
      throw new Error(errorHandled)
    }
  },
  async approveSelfServeOrganization({ commit, dispatch }, payload) {
    commit(Types.BEGIN_APPROVE_SELF_SERVE_ORGANIZATIONS)
    commit(CommonTypes.CLEAR_ERRORS)

    try {
      await selfServe.approveSelfServeOrg(payload)
      commit(Types.SET_APPROVE_SELF_SERVE_ORGANIZATION_SUCCESS)
      commit(Types.END_APPROVE_SELF_SERVE_ORGANIZATIONS)
    } catch (error) {
      if (commonErrors.isTokenInvalid(error)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(error) || commonErrors.isInternalError(error)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      const { error: errorMsg } = await JSON.parse(error.request.response)
      commit(Types.SET_APPROVE_SELF_SERVE_ORGANIZATION_ERROR, error && errorMsg)
      commit(Types.END_APPROVE_SELF_SERVE_ORGANIZATIONS)
      throw new Error(error)
    }
  },
  async rejectSelfServeOrganization({ commit }, payload) {
    commit(Types.BEGIN_REJECT_SELF_SERVE_ORGANIZATIONS)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      await selfServe.rejectSelfServeOrg(payload)
      commit(Types.SET_REJECT_SELF_SERVE_ORGANIZATION_SUCCESS)
      commit(Types.END_REJECT_SELF_SERVE_ORGANIZATIONS)
    } catch (error) {
      if (commonErrors.isTokenInvalid(error)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(error) || commonErrors.isInternalError(error)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      const { error: errorMsg } = await JSON.parse(error.request.response)
      commit(Types.SET_REJECT_SELF_SERVE_ORGANIZATION_ERROR, error && errorMsg)
      commit(Types.END_REJECT_SELF_SERVE_ORGANIZATIONS)
      throw new Error(error)
    }
  },
  async getSelfServeOrganizationInfo({ commit }, payload) {
    commit(Types.BEGIN_GET_SELF_SERVE_ORGANIZATIONS_INFO)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      const { data } = await selfServe.getSelfServeOrgsInfo(payload)
      commit(Types.SET_GET_SELF_SERVE_ORGANIZATION_INFO_SUCCESS, data)
    } catch (error) {
      if (commonErrors.isTokenInvalid(error)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(error) || commonErrors.isInternalError(error)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.SET_GET_SELF_SERVE_ORGANIZATION_INFO_ERROR, 'Error')
      throw new Error(error)
    }
  }
}

export default selfServeActions
