import Vue from 'vue'
import Vuex from 'vuex'
import common from './common'
import auth from './auth'
import organization from './organization'
import user from './user'
import pricing from './pricing'
import configuration from './configuration'
import seats from './seats'
import selfserve from './selfserve'
import exchangeRates from './exchangeRate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'production',
  modules: {
    common,
    auth,
    organization,
    pricing,
    user,
    seats,
    configuration,
    selfserve,
    exchangeRates
  }
})
