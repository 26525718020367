import { user } from '@/services/user'
import { fileHelper } from '@/services/fileHelper'
import { commonErrors } from '@/services/errors/errors-common'
import * as Types from './mutation-types'
import * as CommonTypes from '../common/mutation-types'

const userActions = {
  /**
   * @description Get users
   * @param {Object} commit
   * @param {Object} payload
   */
  async getUsers({ commit }, payload) {
    let response
    commit(Types.BEGIN_FETCHING_USERS)
    commit(Types.CLEAR_USER_LIST_DATA)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_USER_ERRORS)
    try {
      if (!payload.q || payload.q.length === 0 || payload.q.length > 2) {
        response = await user.getPaginatedUsers(payload)
        commit(Types.POPULATE_USERS, response.data.results)
        commit(Types.SET_PAGINATION_USER, response.data.pagination)
      } else {
        commit(Types.SHORT_QUERY_SEARCH_ERROR)
      }
      commit(Types.END_FETCHING_USERS)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_USERS)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Get user by id
   * @param {Object} commit
   * @param {Object} payload
   */
  async getUserById({ commit }, id) {
    let response
    commit(Types.BEGIN_FETCHING_USER)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_USER_ERRORS)
    try {
      response = await user.getUserById(id)
      commit(Types.SET_ORGANIZATION_USER, response.data)
      commit(Types.END_FETCHING_USER)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_USER)
      throw new Error(errorHandled)
    }
  },

  /**
   * @description Action for getting all roles
   * @param {Object} commit Context of the store
   */
  async getAllRoles({ commit }) {
    let response
    try {
      commit(Types.CLEAR_ALL_ROLES)
      commit(Types.BEGIN_FETCHING_ALL_ROLES)
      commit(CommonTypes.CLEAR_ERRORS)

      response = await user.getAllRoles()

      commit(Types.SET_FETCHED_ALL_ROLES, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(Types.END_FETCHING_ALL_ROLES)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(Types.ADD_DUPLICATED_ERROR)
      }
      commit(Types.END_FETCHING_ALL_ROLES)
      throw new Error(error)
    }
  },
  /**
   * @description Action for getting all permissions
   * @param {Object} commit Context of the store
   */
  async getAllPermissions({ commit }) {
    let response
    try {
      commit(Types.CLEAR_ALL_PERMISSIONS)
      commit(Types.BEGIN_FETCHING_ALL_PERMISSIONS)
      commit(CommonTypes.CLEAR_ERRORS)
      commit(Types.CLEAR_ORGANIZATION_USER_ERRORS)

      response = await user.getAllPermissions()

      commit(Types.SET_FETCHED_ALL_PERMISSIONS, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(Types.END_FETCHING_ALL_PERMISSIONS)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(Types.ADD_DUPLICATED_ERROR)
      }
      commit(Types.END_FETCHING_ALL_PERMISSIONS)
      throw new Error(error)
    }
  },
  /**
   * @description Action for getting user permissions by user id
   * @param {Object} commit Context of the store
   * @param {Integer} idUser
   */
  async getPermissionsByUserId({ commit }, idUser = null) {
    let response
    try {
      commit(Types.CLEAR_PERMISSIONS_BY_USER_ID)
      commit(Types.BEGIN_FETCHING_PERMISSIONS_BY_USER_ID)
      commit(CommonTypes.CLEAR_ERRORS)
      commit(Types.CLEAR_ORGANIZATION_USER_ERRORS)

      response = await user.getPermissionsByUserId(idUser)

      commit(Types.SET_FETCHED_PERMISSIONS_BY_USER_ID, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(Types.END_FETCHING_PERMISSIONS_BY_USER_ID)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(Types.ADD_DUPLICATED_ERROR)
      }
      commit(Types.END_FETCHING_PERMISSIONS_BY_USER_ID)
      throw new Error(error)
    }
  },
  /**
   * @description Create user for an organization
   * @param {Object} commit
   * @param {Object} payload
   */
  async createUser({ commit }, payload) {
    let response
    try {
      commit(CommonTypes.LOADING_SHOW)
      commit(CommonTypes.CLEAR_ERRORS)
      commit(Types.CLEAR_ORGANIZATION_USER_ERRORS)
      if (payload.user.image) {
        payload.user.image = await fileHelper.convertToBase64(payload.user.image)
      }
      if (!payload.isEdition) {
        response = await user.createUserForOrganization(payload)
      } else {
        response = await user.updateUserForOrganization(payload)
      }

      commit(Types.SET_ORGANIZATION_USER, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(Types.ADD_DUPLICATED_ERROR)
      }
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(error)
    }
  },
  /**
   * @description Synchronous action for setting up user pemissions
   * @param {Object} commit
   * @param {Object} payload
   */
  setUserPermissions({ commit }, payload) {
    commit(Types.SET_ORGANIZATION_USER_PERMISSIONS, payload)
  },
  /**
   * @description Saves the selected user permissions by the modal
   * @param {Object} commit
   * @param {Object} payload
   */
  setSelectedUserPermissions({ commit }, payload) {
    commit(Types.SET_SELECTED_USER_PERMISSIONS, payload)
  },

  /**
   * @description Delete user, all its links to all organizations and its permissions
   * @param {Object} commit
   * @param {Object} payload
   */
  async removeUser({ commit }, payload) {
    try {
      commit(CommonTypes.LOADING_SHOW)
      commit(CommonTypes.CLEAR_ERRORS)
      await user.removeUserById(payload)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Enable user
   * @param {Object} commit
   * @param {Object} payload
   */
  async addUser({ commit }, payload) {
    try {
      commit(CommonTypes.LOADING_SHOW)
      commit(CommonTypes.CLEAR_ERRORS)
      await user.addUserById(payload)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(errorHandled)
    }
  }
}

export default userActions
