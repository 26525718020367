
/**
 * @description Transform first character of name app to uppercase and render default name app
 * @param {String} value
 */
const appNames = function (value) {
  value = value.toString()
  if (value === 'retargetly_default') {
    value = 'default'
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export default appNames
