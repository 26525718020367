import * as Types from './mutation-types'
import { orderBy } from 'lodash'

const userMutations = {
  [Types.ADD_DUPLICATED_ERROR](state) {
    state.duplicatedUser = true
  },
  [Types.ADD_USER_SERVER_ERROR](state) {
    state.requestUserError = false
  },
  [Types.BEGIN_FETCHING_USERS](state) {
    state.requestUsers = true
  },
  [Types.BEGIN_FETCHING_USER](state) {
    state.requestUser = true
  },
  [Types.CLEAR_ORGANIZATION_USER_ERRORS](state) {
    state.requestUserError = false
    state.duplicatedName = false
    state.shortQuerySearchError = false
  },
  [Types.CLEAR_USER_LIST_DATA](state) {
    state.users = []
  },
  // TODO: consider using this mutations for knowing edition operations
  [Types.CREATE_USER_OPERATION](state) {
    state.userOperation.create = true
    state.userOperation.update = false
  },
  [Types.END_FETCHING_USERS](state) {
    state.requestUsers = false
  },
  [Types.END_FETCHING_USER](state) {
    state.requestUser = false
  },
  [Types.POPULATE_USERS](state, payload) {
    // let users = clone(payload)
    let users = payload.map((user) => {
      return { ...user }
    })
    users = orderBy(users, ['created'], ['desc'])
    state.users = users
  },
  [Types.SET_ORGANIZATION_USER](state, payload) {
    state.userOrganization = payload
  },
  [Types.SET_SELECTED_USER_PERMISSIONS](state, payload) {
    state.selectedUserPermissions = payload
  },

  [Types.SET_PAGINATION_USER](state, payload) {
    let { current, perPage, total } = payload
    state.pagination = { current, perPage, total }
  },
  [Types.SELECTED_USER](state, payload) {
    state.user = payload
  },
  [Types.SHORT_QUERY_SEARCH_ERROR](state) {
    state.shortQuerySearchError = true
    state.requestUsers = false
  },
  [Types.BEGIN_FETCHING_ALL_PERMISSIONS](state) {
    state.fetchingAllPermissions = true
  },
  [Types.BEGIN_FETCHING_PERMISSIONS_BY_USER_ID](state) {
    state.fetchingPermissionsByUserId = true
  },
  [Types.SET_FETCHED_ALL_PERMISSIONS](state, payload) {
    state.allPermissions = payload
  },
  [Types.SET_FETCHED_PERMISSIONS_BY_USER_ID](state, payload) {
    state.permissionsByUserId = payload
  },
  [Types.END_FETCHING_ALL_PERMISSIONS](state) {
    state.fetchingAllPermissions = false
  },
  [Types.END_FETCHING_PERMISSIONS_BY_USER_ID](state) {
    state.fetchingPermissionsByUserId = false
  },
  [Types.CLEAR_ALL_PERMISSIONS](state) {
    state.allPermissions = []
  },
  [Types.CLEAR_PERMISSIONS_BY_USER_ID](state) {
    state.CLEAR_PERMISSIONS_BY_USER_ID = []
  },
  [Types.BEGIN_FETCHING_ALL_ROLES](state) {
    state.fetchingAllRoles = true
  },
  [Types.SET_FETCHED_ALL_ROLES](state, payload) {
    state.allRoles = payload
  },
  [Types.END_FETCHING_ALL_ROLES](state) {
    state.fetchingAllRoles = false
  },
  [Types.CLEAR_ALL_ROLES](state) {
    state.allRoles = []
  }
}

export default userMutations
