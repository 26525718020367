import * as Types from './mutation-types'

const selfServeMutations = {
  [Types.CLEAR_SELF_SERVE_ORGANIZATION_ERRORS](state) {
    state.requestOrganizationsError = false
  },
  [Types.POPULATE_SELF_SERVE_ORGANIZATIONS](state, payload) {
    state.organizations = payload
  },
  [Types.ADD_SELF_SERVE_ORGANIZATION_SERVER_ERROR](state) {
    state.requestOrganizationsError = true
  },
  [Types.BEGIN_FETCHING_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestOrganizations = true
  },
  [Types.END_FETCHING_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestOrganizations = false
  },
  [Types.SET_SELF_SERVE_PAGINATION](state, payload) {
    const { current, perPage, total } = payload
    state.pagination = { current, perPage, total }
  },
  [Types.BEGIN_APPROVE_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestApproveOrganization = true
    state.requestApproveOrganizationError = undefined
    state.requestApproveOrganizationSuccess = false
  },
  [Types.END_APPROVE_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestApproveOrganization = false
  },
  [Types.SET_APPROVE_SELF_SERVE_ORGANIZATION_ERROR](state, payload) {
    state.requestApproveOrganizationError = payload
  },
  [Types.SET_APPROVE_SELF_SERVE_ORGANIZATION_SUCCESS](state) {
    state.requestApproveOrganizationSuccess = true
  },
  [Types.BEGIN_REJECT_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestRejectOrganization = true
    state.requestRejectOrganizationError = undefined
    state.requestRejectOrganizationSuccess = false
  },
  [Types.END_REJECT_SELF_SERVE_ORGANIZATIONS](state) {
    state.requestRejectOrganization = false
  },
  [Types.SET_REJECT_SELF_SERVE_ORGANIZATION_ERROR](state, payload) {
    state.requestApproveOrganizationError = payload
  },
  [Types.SET_REJECT_SELF_SERVE_ORGANIZATION_SUCCESS](state) {
    state.requestRejectOrganizationSuccess = true
  },
  [Types.BEGIN_GET_SELF_SERVE_ORGANIZATIONS_INFO](state) {
    state.requestGetOrganizationInfo = true
    state.requestGetOrganizationInfoError = undefined
    state.requestGetOrganizationInfoSuccess = false
  },
  [Types.SET_GET_SELF_SERVE_ORGANIZATION_INFO_ERROR](state, payload) {
    state.requestGetOrganizationInfoError = payload
    state.requestGetOrganizationInfo = false
  },
  [Types.SET_GET_SELF_SERVE_ORGANIZATION_INFO_SUCCESS](state, payload) {
    state.requestGetOrganizationInfoSuccess = payload
    state.requestGetOrganizationInfo = false
  }
}

export default selfServeMutations
