'use strict'

import getApi from './api'

export const pricing = {
  async getPricingList(filtersObject = {}) {
    const api = await getApi()

    let url = `pricing/list`
    for (const filter in filtersObject) {
      if (filtersObject.hasOwnProperty(filter)) {
        if (filtersObject[filter].length) {
          if (url.indexOf('?') !== -1) {
            url += `&${filter}=${filtersObject[filter].join()}`
          } else {
            url += `?${filter}=${filtersObject[filter].join()}`
          }
        }
      }
    }
    return api.get(url)
  },
  async getClusters() {
    const api = await getApi()

    let url = `common/list/clusters`

    const response = await api.get(url)
    return response
  },
  async getPlatforms() {
    const api = await getApi()

    let url = `common/list/platforms`

    const response = await api.get(url)
    return response
  },
  async getSegmentTypes() {
    const api = await getApi()

    let url = `common/list/types`

    const response = await api.get(url)
    return response
  },
  async getClients() {
    const api = await getApi()

    let url = `common/list/clients`

    const response = await api.get(url)
    return response
  },
  async postRules(payload) {
    const api = await getApi()

    let url = `pricing`

    const response = await api.post(url, payload)
    return response
  },
  async deleteRule(id) {
    const api = await getApi()

    let url = `pricing/${id}`

    const response = await api.delete(url)
    return response
  }
}
