const commonGetters = {
  requestSuccess(state) {
    return state.requestSuccess
  },
  showLoader(state) {
    return state.showLoader
  },
  getRespError(state) {
    return state.respError
  },
  serverError(state) {
    return state.serverError
  },
  tokenValid(state) {
    return state.tokenValid
  }
}

export default commonGetters
