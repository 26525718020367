// Translations for spanish language

/**
 * @description Form translation object
 */
const forms = {
  titles: {
    accountTitle: 'Crear una nueva cuenta',
    accountUpdate: 'Actualizar cuenta',
    uploadsTitle: 'Subir una nueva audiencia'
  },
  inputs: {
    // Auth
    username: 'Usuario',
    email: 'Correo Electronico',
    emailRecovery: 'Correo electrónico de recuperación',
    password: 'Contraseña',
    // Accounts
    accountId: 'Identificador de cuenta',
    accountName: 'Nombre de la cuenta',
    // Audiences
    files: 'Selecciona tu archivo',
    fileName: 'Nombre de la audiencia',
    separator: 'Separador',
    header: 'Archivo con headers'
  },
  buttons: {
    // Common
    accept: 'Aceptar',
    cancel: 'Cancelar',
    edit: 'Actualizar',
    back: 'Volver',
    next: 'Siguiente',
    close: 'Cerrar',
    // Auth
    signIn: 'Ingresar',
    retrySignIn: 'Retry',
    recover: '¿Olvidaste tu contraseña?',
    sendRecover: 'Recuperar Contraseña',
    // Accounts
    addOrganization: 'Agregar Organización',
    editOrganization: 'Actualizar Organización',
    // Audiences
    addUser: 'Agregar Usuario'
  }
}

/**
 * @description Notifications translation object
 */
const notifications = {
  info: {
    urlRemind: 'No olvide copiar la URL para el cliente',
    accountRemind:
      'Dentro de unos minutos verifica tu bussiness manager dentro de <strong>Facebook</strong>. Te llegará una petición de permisos que debes aceptar para terminar la creación.',
    privacy: 'Privacidad',
    terms: 'Términos y Condiciones'
  },
  success: {
    // Common
    adding: 'Creación exitosa',
    updating: 'Actualizacion Exitosa',
    // Auth
    recoverEmail: 'Se ha sido enviado un correo un electrónico para recuperar tu contraseña, revisa tu bandeja de entrada.',
    // Accounts
    accountCreated: 'Cuenta creada exitosamente',
    accountDeleted: 'Cuenta eliminada satisfactoriamente',
    accountUpdated: 'Cuenta editada con exito',
    // Audiences
    audienceCreated: 'Audiencia cargada exitosamente',
    audienceDeleted: 'Audiencia eliminada satisfactoriamente',
    audienceUpdated: 'Audiencia editada con exito'
  },
  errors: {
    errorCredentials: 'Credenciales inválidas, error en usuario o contraseña',
    generalForm: 'Datos de formulario inválidos',
    requestError: '¡Ups algo ha salido mal!',
    accountName: 'Ya existe ese nombre de cuenta',
    audienceName: 'El nombre de audiencia ya existe, corrija en paso 1',
    fileError: 'Falló la carga del archivo, intente nuevamente'
  },
  warnings: {
    noData: 'No hay data que mostrar',
    emptyMapItemData: 'Registro vacio',
    // Accounts
    accountNoData: 'No hay cuentas que mostrar',
    accountActivated: 'Esta cuenta se ha activado con este ID, por tanto no puede editar el id de cuenta.',
    shortQuerySearchError: 'La busqueda realizada es muy corta, debe superar los 2 caracteres.',
    unsetPermissions: 'No hay permisos seleccionados',
    // Audiences
    audienceNoData: 'No hay audiencias que mostrar',
    // Exchange Rates
    ratesNoData: 'No hay cotizaciones que mostrar'
  }
}

/**
 * @description Accounts translation object
 */
const organizations = {
  title: 'Organizaciones',
  steps: {
    s1: 'Nombre y Plataforma',
    // s2: 'Confirmación y Link'
    s2: 'Confirmación'
  },
  subtitles: {
    s1: 'Seleccione una plataforma',
    s2: '',
    s1Update: 'Plataforma de la Cuenta'
  }
}

/**
 * @description Uploads translation object
 */
const users = {
  title: 'Usuarios',
  steps: {
    s1: 'Nombre y Archivo',
    s2: 'Mapeo de Columnas',
    s3: 'Selección de Cuentas'
  },
  subtitles: {
    s1: '',
    s2: '',
    s3: 'Seleccione que cuentas vincular a la audiencia'
  }
}

const seats = {
  title: 'Seats',
  auth_btn: 'Autorizar Seats',
  select_one: 'Debes seleccionar al menos un seat'
}

const tables = {
  status: 'Activa',
  edit: 'Editar',
  config: 'Configurar',
  remove: 'Borrar',
  add: 'Habilitar'
}

/**
 * @description Dashboard translation object
 */
const dashboard = {
  title: 'Dashboard',
  menu: {
    logout: 'Salir'
  }
}

/**
 * @description Exchange Rates translation object
 */
const exchangeRates = {
  title: 'Exchange Rates',
  add: {
    rate: 'Agregar Cotización',
    createRate: 'Crear Cotización'
  }
}

/**
 * @description Exportable grouped spanish translation object
 */
const es = {
  dashboard,
  forms,
  tables,
  seats,
  organizations,
  users,
  exchangeRates,
  errors: notifications.errors,
  info: notifications.info,
  success: notifications.success,
  warnings: notifications.warnings
}

export default es
