import Vue from 'vue'
import VueLodash from 'vue-lodash'
import VueI18n from 'vue-i18n'
import messages from './i18n/'
import Buefy from 'buefy'
import VeeValidate, { Validator } from 'vee-validate'
import App from './App.vue'
import Fragment from 'vue-fragment'

import router from './router'
import store from '@/store'
import es from 'vee-validate/dist/locale/es'

import appNames from '@/filters/app-names'
import firstLetterUppercase from '@/filters/firstLetterUppercase'
import VueJsonToCsv from 'vue-json-to-csv'

import('./i18n/validation')

Vue.use(Fragment.Plugin)
Vue.config.productionTip = false

/**
 * Config for Vue Lodash
 */
const lodashOptions = { name: 'lodash' }
Vue.use(VueLodash, lodashOptions)

/**
 * Config for Internationalization
 */
Vue.use(VueI18n)

/**
 * Config for i18n module
 */
const i18n = new VueI18n({
  locale: 'es', // Set locale
  fallbackLocale: 'es',
  messages // Set locale messages
})

// Global Filters
Vue.filter('appNames', appNames)
Vue.filter('firstLetterUppercase', firstLetterUppercase)

/**
 * Config for Validation
 */
Validator.localize('es', es)
Vue.use(VeeValidate)

/**
 * Config for Buefy module
 */
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(VueJsonToCsv)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
