const pricingGetters = {
  pricings(state) {
    return state.prices
  },
  clients(state) {
    return state.clients
  },
  clusters(state) {
    return state.clusters
  },
  platforms(state) {
    return state.platforms
  },
  segmentType(state) {
    return state.segmentType
  },
  requestPricings(state) {
    return state.requestPrices
  },
  requestPlatforms(state) {
    return state.requestPlatforms
  },
  requestClusters(state) {
    return state.requestClusters
  },
  requestSegmentTypes(state) {
    return state.requestSegmentTypes
  },
  requestClients(state) {
    return state.requestClients
  },
  postingRules(state) {
    return state.postingRules
  },
  requestPricesError(state) {
    return state.requestPricesError
  },
  requestClientsError(state) {
    return state.requestClientsError
  },
  requestPlatformsError(state) {
    return state.requestPlatformsError
  },
  requestSegmentsError(state) {
    return state.requestSegmentsError
  },
  requestClustersError(state) {
    return state.requestClustersError
  }
}

export default pricingGetters
