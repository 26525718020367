export const ADD_DUPLICATED_ERROR = 'ADD_DUPLICATED_ERROR'
export const ADD_ORGANIZATION_SERVER_ERROR = 'ADD_ORGANIZATION_SERVER_ERROR'
export const BEGIN_FETCHING_ORGANIZATIONS = 'BEGIN_FETCHING_ORGANIZATION'
export const BEGIN_FETCHING_THEMES = 'BEGIN_FETCHING_THEMES'
export const CLEAR_ORGANIZATION_ERRORS = 'CLEAR_ORGANIZATION_ERRORS'
export const CLEAR_ORGANIZATIONS_DATA = 'CLEAR_ORGANIZATIONS_DATA'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const END_FETCHING_ORGANIZATIONS = 'END_FETCHING_ORGANIZATIONS'
export const END_FETCHING_THEMES = 'END_FETCHING_THEMES'
export const SET_PRODUCT_ACTIVATION = 'SET_PRODUCT_ACTIVATION'
export const POPULATE_ORGANIZATIONS = 'POPULATE_ORGANIZATIONS'
export const SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION'
export const SET_APP_THEMES = 'SET_APP_THEMES'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_ORGANIZATION_PRODUCTS = 'SET_ORGANIZATION_PRODUCTS'
export const SET_PAGINATION_ORGANIZATION = 'SET_PAGINATION_ORGANIZATION'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
