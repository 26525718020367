import commonActions from './actions'
import commonMutations from './mutations'
import commonGetters from './getters'

const common = {
  state: {
    errors: [],
    showLoader: false,
    requestSuccess: false,
    serverError: false,
    tokenValid: false,
    respError: null
  },
  actions: commonActions,
  mutations: commonMutations,
  getters: commonGetters
}

export default common
