import authActions from './actions'
import authMutations from './mutations'
import authGetters from './getters'

const auth = {
  state: {
    user: {},
    errorLogin: null,
    loginSuccess: false,
    token: null
  },
  actions: authActions,
  mutations: authMutations,
  getters: authGetters
}

export default auth
