const authGetters = {
  loggedUser(state) {
    return state.user
  },
  errorLogin(state) {
    return state.errorLogin
  },
  loginSuccess(state) {
    return state.loginSuccess
  }
}

export default authGetters
