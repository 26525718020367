'user strict'

import getApi from './api'

export const seats = {
  /**
   * @getSeats get the seats list to be authorized
   * @param {}
   */
  async getSeats() {
    const api = await getApi()

    return api.get('/seat')
  },
  /**
   * @description
   * @param {}
   */
  async authorize_seats(idSeats) {
    const api = await getApi()

    return api.post('/seat/authorize', { seats: idSeats })
  }
}
