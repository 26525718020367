import { organization } from '@/services/organization'
import { fileHelper } from '@/services/fileHelper'
import { commonErrors } from '@/services/errors/errors-common'
import * as Types from './mutation-types'
import * as CommonTypes from '../common/mutation-types'

const accountActions = {
  /**
   * @description Action for creating organization
   * @param {Object} commit context from the store
   * @param {Object} payload Organization data from the view
   */
  async createOrganization({ commit }, payload) {
    let response
    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_ERRORS)
    try {
      if (payload.organization.file) {
        payload.organization.base64ImageFile = await fileHelper.convertToBase64(payload.organization.file)
      }
      if (!payload.isEdition) {
        response = await organization.createOrganization(payload.organization)
      } else {
        response = await organization.updateOrganization(payload.organization.idOrganization, payload.organization)
      }
      commit(Types.SET_ORGANIZATION, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(Types.ADD_DUPLICATED_ERROR)
        commit(CommonTypes.LOADING_HIDE)
        throw new Error(error)
      }
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(error)
    }
  },
  /**
   * @description Action for enabling a product
   * @param {Object} commit Context of the store
   * @param {Boolean} payload contains the organization id and application id to put enabled
   */
  async setActivationProduct({ commit }, payload) {
    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_ERRORS)
    try {
      await organization.setActivationProductForOrganization(payload)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(Types.SET_PRODUCT_ACTIVATION, payload)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_ORGANIZATIONS)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Action for getting organization with a pagination setting
   * @param {Object} commit Context of the store
   * @param {Object} payload Object with pagination configuration
   */
  async getOrganizations({ commit }, payload) {
    let response
    commit(Types.BEGIN_FETCHING_ORGANIZATIONS)
    commit(Types.CLEAR_ORGANIZATIONS_DATA)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_ERRORS)
    try {
      response = await organization.getPaginatedOrganizations(payload)
      commit(Types.POPULATE_ORGANIZATIONS, response.data.results)
      commit(Types.SET_PAGINATION_ORGANIZATION, response.data.pagination)
      commit(Types.END_FETCHING_ORGANIZATIONS)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_ORGANIZATIONS)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Action for getting an account by ID
   * @param {Object} commit Context commit from the store
   * @param {Integer} id Contains Account id to get from the view
   */
  async getOrganizationById({ commit }, id, getChildren = true, getPricingRules = true, getSegments = true) {
    let response
    commit(Types.BEGIN_FETCHING_ORGANIZATIONS)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_ERRORS)
    try {
      response = await organization.getOrganizationById(id, getChildren, getPricingRules, getSegments)
      commit(Types.END_FETCHING_ORGANIZATIONS)
      commit(Types.SET_ORGANIZATION, response.data)
      commit(Types.SET_ORGANIZATION_PRODUCTS, response.data.products)
    } catch (error) {
      if (commonErrors.isTokenInvalid(error)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(error) || commonErrors.isInternalError(error)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else if (commonErrors.isTimeoutError(error)) {
        console.log(error)
      }
      commit(Types.END_FETCHING_ORGANIZATIONS)
    }
  },

  /**
   * @description
   * @param {Object} commit  Context commit from the store
   */
  async getThemesForApps({ commit }) {
    let response
    commit(Types.BEGIN_FETCHING_THEMES)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      response = await organization.getAppThemes()
      commit(Types.END_FETCHING_THEMES)
      commit(Types.SET_APP_THEMES, response.data.hasOwnProperty('response') ? response.data.response : response.data)
    } catch (error) {
      if (commonErrors.isTokenInvalid(error)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(error) || commonErrors.isInternalError(error)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_ORGANIZATION_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else if (commonErrors.isTimeoutError(error)) {
        console.log(error)
      }
      commit(Types.END_FETCHING_THEMES)
    }
  },
  /**
   * @description Action for setting app configuration for organizations
   * @param {Object} commit Context from the store
   * @param {Object} payload payload with the app config data to set for the organization
   */
  async setAppConfiguration({ commit }, payload) {
    let response
    commit(CommonTypes.LOADING_SHOW)
    commit(CommonTypes.CLEAR_ERRORS)
    commit(Types.CLEAR_ORGANIZATION_ERRORS)
    try {
      if (payload.organization.image) {
        // const { data } = await fileHelper.convertToBase64(payload.organization.image)
        payload.organization.image = await fileHelper.convertToBase64(payload.organization.image)
      }
      if (!payload.isEdition) {
        response = await organization.setAppForOrganization(payload)
      } else {
        response = await organization.updateAppForOrganization(payload)
      }
      commit(Types.SET_ORGANIZATION, response.data.hasOwnProperty('response') ? response.data.response : response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (
        commonErrors.isNetworkError(errorHandled) ||
        commonErrors.isInternalError(errorHandled) ||
        commonErrors.isBadRequest(errorHandled)
      ) {
        commit(CommonTypes.ADD_SERVER_ERROR)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
        commit(CommonTypes.LOADING_HIDE)
      }
      commit(CommonTypes.REQUEST_FAIL)
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description
   * @param {Object} commit Context of the store
   * @param {Object} payload Object that contains the organization data to update
   */
  async updateOrganization({ commit }, payload) {},
  async fetchCountries({ commit }, payload) {
    const response = await organization.fetchCountries()
    return response.data.response
  }
}

export default accountActions
