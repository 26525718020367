import * as Types from './mutation-types'

const seatsMutations = {
  [Types.GET_SEATS] (state, payload) {
    state.seats = payload
  },
  [Types.AUTHORIZE_SEATS] (state, payload) {
    state.authSeats = payload
  },
  [Types.AUTHORIZED_SEATS_OK_CLEAR_ALL] (state) {
    state.authSeatsOk = []
  },
  [Types.AUTHORIZED_SEATS_ERROR_CLEAR_ALL] (state) {
    state.authSeatsError = []
  },
  [Types.AUTHORIZED_SEATS_OK] (state, payload) {
    state.authSeatsOk = payload
  },
  [Types.AUTHORIZED_SEATS_ERROR] (state, payload) {
    state.authSeatsError = payload
  }
}

export default seatsMutations
