const exchangeRateGetters = {
  exchangeRates(state) {
    return state.exchangeRates
  },
  requestExchangeRates(state) {
    return state.requestExchangeRates
  },
  requestExchangeRatesError(state) {
    return state.requestExchangeRatesError
  },
  exchangeRatesCountries(state) {
    return state.exchangeRatesCountries
  },
  requestExchangeRatesCountries(state) {
    return state.requestExchangeRatesCountries
  },
  requestExchangeRatesCountriesError(state) {
    return state.requestExchangeRatesCountriesError
  },
  getNewExchangeRate(state) {
    return state.newExchangeRate
  },
  requestEditExchangeRate(state) {
    return state.requestEditExchangeRate
  },
  successEditExchangeRate(state) {
    return state.successEditExchangeRate
  },
  requestRemoveExchangeRate(state) {
    return state.requestRemoveExchangeRate
  },
  successRemoveExchangeRate(state) {
    return state.successRemoveExchangeRate
  }
}

export default exchangeRateGetters
