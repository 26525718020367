export const ADD_DUPLICATED_ERROR = 'ADD_DUPLICATED_ERROR'
export const ADD_USER_SERVER_ERROR = 'ADD_USER_SERVER_ERROR'
export const BEGIN_FETCHING_USERS = 'BEGIN_FETCHING_USERS'
export const BEGIN_FETCHING_USER = 'BEGIN_FETCHING_USER'
export const CLEAR_USER_LIST_DATA = 'CLEAR_USER_LIST_DATA'
export const CLEAR_ORGANIZATION_USER_ERRORS = 'CLEAR_ORGANIZATION_USER_ERRORS'
export const CREATE_USER_OPERATION = 'CREATE_USER_OPERATION'
export const END_FETCHING_USERS = 'END_FETCHING_USERS'
export const END_FETCHING_USER = 'END_FETCHING_USER'
export const POPULATE_USERS = 'POPULATE_USERS'
export const SELECTED_USER = 'SELECTED_USER'
export const SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER'
export const SET_PAGINATION_USER = 'SET_PAGINATION_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SHORT_QUERY_SEARCH_ERROR = 'SHORT_QUERY_SEARCH_ERROR'

export const SET_SELECTED_USER_PERMISSIONS = 'SET_SELECTED_USER_PERMISSIONS'

export const BEGIN_FETCHING_ALL_PERMISSIONS = 'BEGIN_FETCHING_ALL_PERMISSIONS'
export const BEGIN_FETCHING_PERMISSIONS_BY_USER_ID = 'BEGIN_FETCHING_PERMISSIONS_BY_USER_ID'
export const BEGIN_FETCHING_ALL_ROLES = 'BEGIN_FETCHING_ALL_ROLES'

export const SET_FETCHED_ALL_PERMISSIONS = 'SET_FETCHED_ALL_PERMISSIONS'
export const SET_FETCHED_PERMISSIONS_BY_USER_ID = 'SET_FETCHED_PERMISSIONS_BY_USER_ID'
export const SET_FETCHED_ALL_ROLES = 'SET_FETCHED_ALL_ROLES'

export const END_FETCHING_ALL_PERMISSIONS = 'END_FETCHING_ALL_PERMISSIONS'
export const END_FETCHING_PERMISSIONS_BY_USER_ID = 'END_FETCHING_PERMISSIONS_BY_USER_ID'
export const END_FETCHING_ALL_ROLES = 'END_FETCHING_ALL_ROLES'

export const CLEAR_ALL_PERMISSIONS = 'CLEAR_ALL_PERMISSIONS'
export const CLEAR_PERMISSIONS_BY_USER_ID = 'CLEAR_PERMISSIONS_BY_USER_ID'
export const CLEAR_ALL_ROLES = 'CLEAR_ALL_ROLES'
