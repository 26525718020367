import selfserveActions from './actions'
import selfserveMutations from './mutations'
import selfserveGetters from './getters'

const selfserve = {
  state: {
    organizations: [],
    requestOrganizations: false,
    requestOrganizationsError: false,
    pagination: {
      current: null,
      total: null
    },
    requestApproveOrganization: false,
    requestApproveOrganizationError: undefined,
    requestApproveOrganizationSuccess: false,
    requestRejectOrganizationError: undefined,
    requestRejectOrganizationSuccess: false,
    requestGetOrganizationInfo: false,
    requestGetOrganizationInfoError: undefined,
    requestGetOrganizationInfoSuccess: false
  },
  actions: selfserveActions,
  mutations: selfserveMutations,
  getters: selfserveGetters
}

export default selfserve
