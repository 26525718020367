export const BEGIN_FETCHING_SELF_SERVE_ORGANIZATIONS = 'BEGIN_FETCHING_SELF_SERVE_ORGANIZATIONS'
export const CLEAR_SELF_SERVE_ORGANIZATION_ERRORS = 'CLEAR_SELF_SERVE_ORGANIZATION_ERRORS'
export const POPULATE_SELF_SERVE_ORGANIZATIONS = 'POPULATE_SELF_SERVE_ORGANIZATIONS'
export const SET_SELF_SERVE_PAGINATION = 'SET_SELF_SERVE_PAGINATION'
export const END_FETCHING_SELF_SERVE_ORGANIZATIONS = 'END_FETCHING_SELF_SERVE_ORGANIZATIONS'
export const ADD_SELF_SERVE_ORGANIZATION_SERVER_ERROR = 'ADD_SELF_SERVE_ORGANIZATION_SERVER_ERROR'

export const BEGIN_APPROVE_SELF_SERVE_ORGANIZATIONS = 'BEGIN_APPROVE_SELF_SERVE_ORGANIZATIONS'
export const END_APPROVE_SELF_SERVE_ORGANIZATIONS = 'END_APPROVE_SELF_SERVE_ORGANIZATIONS'
export const SET_APPROVE_SELF_SERVE_ORGANIZATION_ERROR = 'SET_APPROVE_SELF_SERVE_ORGANIZATION_ERROR'
export const SET_APPROVE_SELF_SERVE_ORGANIZATION_SUCCESS = 'SET_APPROVE_SELF_SERVE_ORGANIZATION_SUCCESS'

export const BEGIN_REJECT_SELF_SERVE_ORGANIZATIONS = 'BEGIN_REJECT_SELF_SERVE_ORGANIZATIONS'
export const END_REJECT_SELF_SERVE_ORGANIZATIONS = 'END_REJECT_SELF_SERVE_ORGANIZATIONS'
export const SET_REJECT_SELF_SERVE_ORGANIZATION_ERROR = 'SET_REJECT_SELF_SERVE_ORGANIZATION_ERROR'
export const SET_REJECT_SELF_SERVE_ORGANIZATION_SUCCESS = 'SET_REJECT_SELF_SERVE_ORGANIZATION_SUCCESS'

export const BEGIN_GET_SELF_SERVE_ORGANIZATIONS_INFO = 'BEGIN_GET_SELF_SERVE_ORGANIZATIONS_INFO'
export const SET_GET_SELF_SERVE_ORGANIZATION_INFO_ERROR = 'SET_GET_SELF_SERVE_ORGANIZATION_INFO_ERROR'
export const SET_GET_SELF_SERVE_ORGANIZATION_INFO_SUCCESS = 'SET_GET_SELF_SERVE_ORGANIZATION_INFO_SUCCESS'
