'use strict'

import axios from 'axios'
import SERVER from '../server'
import { user } from '../services/user'

import getApi from './api'

const config = {
  baseURL: `${SERVER.api}`,
  timeout: process.env.VUE_APP_DEFAULT_TIMEOUT
}

const authAxios = axios.create(config)

export const auth = {
  /**
   * @description Sign in user by redirecting to back who then redirects to auth0
   * @returns {Promise}
   */
  loginToAuth0() {
    return window.location.replace(`${process.env.VUE_APP_API_BASE_URI}/login`)
  },
  /**
   * @description Validates user code and gets tokens
   * @param {string} code User code
   * @returns {Promise}
   */
  getUser(code) {
    return authAxios.get(`/login/user?code=${code}`)
  },
  /**
   * @description Set valid user data into the localstorage for session handling
   * @param {Object} payload Contains the data obtained for valid logged user from the server
   */
  async setUser(payload) {
    window.localStorage.setItem('user', payload.username)
    window.localStorage.setItem('user_id', payload.id)
    window.localStorage.setItem('current_organization_id', payload.organization.id)
    window.localStorage.setItem('current_organization_name', payload.organization.name)
    window.localStorage.setItem('token', payload.accessToken)
    window.localStorage.setItem('rt', payload.refreshToken)
    window.localStorage.setItem('tokenExpires', payload.accessToken.accessTokenExpires)

    console.log(payload)

    let loggedUserPermissions = await user.getPermissionsByUserId(payload.auth0Id)
    window.localStorage.setItem('loggedUserPermissions', JSON.stringify(loggedUserPermissions.data))
  },
  /**
   * @description Get authenticated user data from localstorage
   * @returns {Object}
   */
  getAuthenticatedUser() {
    const user = {
      username: window.localStorage.getItem('user'),
      id: window.localStorage.getItem('user_id'),
      organization: {
        id: window.localStorage.getItem('current_organization_id'),
        name: window.localStorage.getItem('current_organization_name')
      }
    }
    return user
  },
  /**
   * @description Logout in Auth0 and destroy user session on localstorage
   * @returns {Promise}
   */
  async signOut() {
    const api = await getApi()

    // Logout in Auth0
    const logout = await api.get(`/logout?callback=${process.env.VUE_APP_BASE_URI}/login`)

    // Destroy Session, unset user data
    window.localStorage.clear()

    window.location.replace(logout.data.redirect_to)

    return logout
  }
}
