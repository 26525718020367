import { exchangeRate } from '@/services/exchangeRate'
import { commonErrors } from '@/services/errors/errors-common'
import * as Types from './mutation-types'
import * as CommonTypes from '../common/mutation-types'

const exchangeRateActions = {
  /**
   * @description Get exchange rates
   * @param {Object} commit
   * @param {Object} payload
   */
  async getExchangeRates({ commit }, payload) {
    let response
    commit(Types.BEGIN_FETCHING_EXCHANGE_RATES)
    commit(Types.CLEAR_EXCHANGE_RATES_LIST_DATA)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      response = await exchangeRate.getExchangeRates(payload)
      commit(Types.POPULATE_EXCHANGE_RATES, response.data)
      commit(Types.END_FETCHING_EXCHANGE_RATES)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_EXCHANGE_RATES)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Get exchange rates
   * @param {Object} commit
   */
  async getExchangeRatesCountries({ commit }) {
    let response
    commit(Types.BEGIN_FETCHING_EXCHANGE_RATES_COUNTRIES)
    commit(Types.CLEAR_EXCHANGE_RATES_COUNTRIES_LIST_DATA)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      response = await exchangeRate.getExchangeRatesCountries()
      commit(Types.POPULATE_EXCHANGE_RATES_COUNTRIES, response.data)
      commit(Types.END_FETCHING_EXCHANGE_RATES_COUNTRIES)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_FETCHING_EXCHANGE_RATES_COUNTRIES)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Create exchange rate
   * @param {Object} commit
   * @param {Object} payload
   */
  async createExchangeRate({ commit }, payload) {
    let response
    try {
      commit(CommonTypes.LOADING_SHOW)
      commit(CommonTypes.CLEAR_ERRORS)
      response = await exchangeRate.createExchangeRate(payload)
      commit(Types.SET_EXCHANGE_RATES, response.data)
      commit(CommonTypes.REQUEST_SUCCESS)
      commit(CommonTypes.LOADING_HIDE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      } else if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      } else {
        commit(CommonTypes.ADD_ERROR, error.response.data.error)
      }
      commit(CommonTypes.LOADING_HIDE)
      throw new Error(error)
    }
  },
  /**
   * @description edit exchange rate
   * @param {Object} commit
   * @param {Object} payload
   */
  async editExchangeRate({ commit }, payload) {
    commit(Types.BEGIN_EDITING_EXCHANGE_RATE)
    commit(CommonTypes.CLEAR_ERRORS)
    try {
      await exchangeRate.editExchangeRate(payload)
      commit(Types.SUCCESS_EDITING_EXCHANGE_RATE, true)
      commit(Types.END_EDITING_EXCHANGE_RATE)
    } catch (error) {
      console.log(error)
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_EDITING_EXCHANGE_RATE)
      throw new Error(errorHandled)
    }
  },
  /**
   * @description Delete rate
   * @param {Object} commit
   * @param {Object} payload
   */
  async removeRate({ commit }, payload) {
    try {
      commit(Types.BEGIN_REMOVE_EXCHANGE_RATE)
      commit(CommonTypes.CLEAR_ERRORS)
      await exchangeRate.removeRateById(payload)
      commit(Types.SUCCESS_REMOVE_EXCHANGE_RATE, true)
      commit(Types.END_REMOVE_EXCHANGE_RATE)
    } catch (error) {
      let errorHandled = error
      if (commonErrors.isTokenInvalid(errorHandled)) {
        commit(CommonTypes.TOKEN_INVALID)
      }
      if (commonErrors.isNetworkError(errorHandled) || commonErrors.isInternalError(errorHandled)) {
        commit(CommonTypes.ADD_SERVER_ERROR)
        commit(Types.ADD_USER_SERVER_ERROR)
        commit(CommonTypes.REQUEST_FAIL)
      }
      commit(Types.END_REMOVE_EXCHANGE_RATE)
      throw new Error(errorHandled)
    }
  }
}

export default exchangeRateActions
