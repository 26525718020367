const organizationGetters = {
  organization (state) {
    return state.organization
  },
  organizationAppThemes (state) {
    return state.organizationAppThemes
  },
  organizationProducts (state) {
    return state.organizationProducts.filter(product => product.id !== 1)
  },
  organizations (state) {
    return state.organizations
  },
  organizationsPagination (state) {
    return state.pagination
  },
  totalOrganizations (state) {
    return state.pagination.total !== state.pagination.perPage ? state.pagination.total * state.pagination.perPage : state.pagination.total
  },
  requestOrganizations (state) {
    return state.requestOrganizations
  },
  duplicatedName (state) {
    return state.duplicatedName
  },
  requestOrganizationError (state) {
    return state.requestOrganizationError
  },
  organizationCreation (state) {
    return state.organizationOperation.create
  },
  organizationUpdate (state) {
    return state.organizationOperation.update
  }
}

export default organizationGetters
