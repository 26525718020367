export const BEGIN_FETCHING_EXCHANGE_RATES = 'BEGIN_FETCHING_EXCHANGE_RATES'
export const END_FETCHING_EXCHANGE_RATES = 'END_FETCHING_EXCHANGE_RATES'
export const CLEAR_EXCHANGE_RATES_LIST_DATA = 'CLEAR_EXCHANGE_RATES_LIST_DATA'
export const POPULATE_EXCHANGE_RATES = 'POPULATE_EXCHANGE_RATES'
export const BEGIN_FETCHING_EXCHANGE_RATES_COUNTRIES = 'BEGIN_FETCHING_EXCHANGE_RATES_COUNTRIES'
export const END_FETCHING_EXCHANGE_RATES_COUNTRIES = 'END_FETCHING_EXCHANGE_RATES_COUNTRIES'
export const CLEAR_EXCHANGE_RATES_COUNTRIES_LIST_DATA = 'CLEAR_EXCHANGE_RATES_COUNTRIES_LIST_DATA'
export const POPULATE_EXCHANGE_RATES_COUNTRIES = 'POPULATE_EXCHANGE_RATES_COUNTRIES'
export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES'
export const BEGIN_EDITING_EXCHANGE_RATE = 'BEGIN_EDITING_EXCHANGE_RATE'
export const END_EDITING_EXCHANGE_RATE = 'END_EDITING_EXCHANGE_RATE'
export const SUCCESS_EDITING_EXCHANGE_RATE = 'SUCCESS_EDITING_EXCHANGE_RATE'
export const BEGIN_REMOVE_EXCHANGE_RATE = 'BEGIN_REMOVE_EXCHANGE_RATE'
export const END_REMOVE_EXCHANGE_RATE = 'END_REMOVE_EXCHANGE_RATE'
export const SUCCESS_REMOVE_EXCHANGE_RATE = 'SUCCESS_REMOVE_EXCHANGE_RATE'
